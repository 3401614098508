import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {
    Container,
    Typography,
    TextField,
    Button,
    CssBaseline,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
} from "@mui/material";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import BizLangIcon from "../../assets/bizlang_logo.svg";

const ForgotPWPage = () => {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const auth = getAuth();
    const [success, setSuccess] = useState(false);

    const useResetPW = async () => {
        if (email === "") {
            setError("Email is empty");
        } else {
            try {
                sendPasswordResetEmail(auth, email)
                    .then(function () {
                        // Password reset email sent.
                        setSuccess(true);
                    })
                    .catch((error) => {
                        setError(
                            "Cannot send email for a reset link. Try again."
                        );
                    });
            } catch (error) {
                setError("Cannot send email for a reset link. Try again.");
            }
        }
    };
    return (
        <ThemeProvider theme={styles}>
            <Container
                component="main"
                maxWidth="xl"
                style={{ padding: 0, height: "100%", margin: 0, flexGrow: 1 }}
            >
                <CssBaseline />
                <AppBar
                    position="relative"
                    style={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                marginTop: "-10px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                src={BizLangIcon}
                                height={75}
                                width={100}
                            />
                        </Typography>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "0",
                                padding: "0",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.6rem",
                                fontFamily: "monospace",
                                paddingTop: "15px",
                            }}
                        >
                            BIZLANG
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container component="container" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h4">
                            Enter your email
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                    setSuccess(false);
                                }}
                            />

                            {!(error === "") ? (
                                <Typography
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        margin: 0,
                                        maxWidth: "inherit",
                                    }}
                                >
                                    {error}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            {success ? (
                                <Typography
                                    style={{
                                        color: "green",
                                        fontWeight: "bold",
                                        margin: 0,
                                    }}
                                >
                                    Check your email for the reset password
                                    link!
                                </Typography>
                            ) : (
                                <></>
                            )}

                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={useResetPW}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Container>
        </ThemeProvider>
    );
};

export default ForgotPWPage;
