import React, { useEffect, useState, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { signOut } from "../../constants/GoogleAuth";
//import { styles } from "../news/styles";
import { ThreeDot } from "react-loading-indicators";
import { useStore } from "../../constants/store/store";
import { useNavigate, useLocation } from "react-router-dom";
import { TextOptions } from "../../components/chat/textOptions";
import { APIStoreVocab, APIStoreChat } from "../../actions/APIstoreChat";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import { APISearch } from "../../actions/api";

const NewsSearch = () => {
  const [userInput, setUserInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedArticle, setExpandedArticle] = useState(null);
  const [isTopHeadlines, setIsTopHeadlines] = useState(true); // Track if displaying top headlines

  // Fetch top headlines on component mount
  useEffect(() => {
    const fetchTopHeadlines = async () => {
      setIsLoading(true);
      try {
        const res = await fetch("https://gnews.io/api/v4/top-headlines?category=general&country=us&apikey=e66edf261f41b941ac40fde9aa8e61a2");
        const data = await res.json();
        setSearchResults(data.articles);
        console.log("Top headlines response:", data);
      } catch (error) {
        console.error("Error fetching top headlines:", error);
      }
      setIsLoading(false);
    };

    fetchTopHeadlines();
  }, []);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setIsTopHeadlines(false);
    try {
      const res = await APISearch(userInput);
      setSearchResults(res.data.articles);
      console.log("News search response:", res.data);
    } catch (error) {
      console.error("Error searching for news:", error);
    }
    setIsLoading(false);
    setUserInput("");
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const toggleArticleExpansion = (index) => {
    setExpandedArticle(expandedArticle === index ? null : index);
  };

  return (
    <div style={styles.container}>
      <div style={styles.searchContainer}>
        <input
          type="text"
          placeholder="SEARCH"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          style={styles.input}
        />
        <button onClick={handleSearch} style={styles.button}>
          GO
        </button>
      </div>
      {isTopHeadlines && <h1 style={styles.heading}>Top Headlines</h1>}
      {isLoading && <ThreeDot />}
      <div style={styles.articleList}>
        {searchResults.map((article, index) => (
          <div
            key={index}
            style={{
              ...styles.articleCard,
              ...(expandedArticle === index ? styles.expandedArticle : {}),
            }}
            onClick={() => toggleArticleExpansion(index)}
          >
            <img src={article.image} alt={article.title} style={styles.articleImage} />
            <div style={styles.articleContent}>
              <h2>{article.title}</h2>
              <p>{article.description}</p>
              {expandedArticle === index && (
                <>
                  <p>{article.content}</p>
                  <p>Source: {article.source.name}</p>
                  <p>Published at: {article.publishedAt}</p>
                  <a href={article.url} target="_blank" rel="noopener noreferrer" style={styles.readMoreLink}>
                    Read More
                  </a>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsSearch;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    width: '100%', // Take full width of the viewport 
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  input: {
    padding: '0.5rem',
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
  button: {
    padding: '0.5rem 1rem',
    fontSize: '1rem',
    cursor: 'pointer',
    backgroundColor: 'green',
    color: 'white'
  },
  articleList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    width: '100%', // Ensure the article list also takes the full width
    
  },
  articleCard: {
    border: "1px solid #ccc",
    borderRadius: "0.5rem",
    overflow: "hidden",
    width: "300px",
    cursor: "pointer",
  },
  expandedArticle: {
    width: "100%",
  },
  articleImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
  },
  articleContent: {
    padding: "1rem",
  },
  readMoreLink: {
    display: "block",
    textAlign: "center",
    backgroundColor: "green",
    color: "#fff",
    textDecoration: "none",
    padding: "0.5rem",
  }
};