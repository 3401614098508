import React, { useEffect, useState } from "react";
import { Container, CssBaseline, Box, Grid, Button } from "@mui/material";
import OptionDialog from "../../components/SetOptionsUI/optionDialog";
import { useStore } from "../../constants/store/store";
import SetDialog from "../../components/flashcards/setDialog";
import SetBadge from "../../components/flashcards/set";
import vocabs from "../../assets/vocabs/vocabs.json";
import qna from "../../assets/vocabs/qna.json";
import { APILoadVocab } from "../../actions/APIstoreChat";
import ErrorDialog from "../../components/misc/errorDialog";
import { useNavigate } from "react-router-dom";

const DashboardPage = () => {
    const [emptyVocab, setEmptyVocab] = useState(false);
    const [previewSet, setPreviewSet] = useState(false);
    const [chosenSetTitle, setChosenSetTitle] = useState("");
    const [chosenSetDesc, setChosenSetDesc] = useState("");
    const [chosenVocabs, setChosenVocabs] = useState([]);
    const [chosenQnas, setChosenQnas] = useState([]);
    const [chosenIndustry, setChosenIndustry] = useState(
        useStore((state) => state.industry)
    );
    const [openIndustryOptions, setOpenIndustryOptions] = useState(false);
    const { setIndustry, setToken } = useStore();

    const handleSaveIndustry = (val) => {
        setChosenIndustry(val);
        setIndustry(val);
    };
    var sets = vocabs[chosenIndustry.toLowerCase()];
    var qnaSets = qna[chosenIndustry.toLowerCase()];
    const [industryVocabs, setIndustryVocabs] = useState([]);
    const navigate = useNavigate();

    return (
        <Box
            component="main"
            style={{
                display: "flex",
                padding: 0,
                height: "100%",
                margin: 0,
                flexGrow: 1,
            }}
        >
            <CssBaseline />
            <Container
                component="container"
                maxWidth="xl"
                sx={{
                    background: "linear-gradient(to bottom, #FFFFFF, #74D680)",
                    height: "fit-content",
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    minHeight="100vh"
                    flexDirection="column"
                >
                    {chosenIndustry !== "" ? (
                        <Button
                            sx={{
                                margin: "20px",
                                backgroundColor: "#509458",
                                color: "#FFFFFF",
                                padding: "0.5rem",
                                fontWeight: "bold",
                                "&:hover": {
                                    backgroundColor: "#EFFFEF",
                                    color: "#509458",
                                },
                                minWidth: "7rem",
                                borderRadius: "20px",
                            }}
                            onClick={() => {
                                setOpenIndustryOptions(true);
                            }}
                        >
                            {chosenIndustry}
                        </Button>
                    ) : (
                        <Button
                            sx={{
                                margin: "20px",
                                backgroundColor: "#509458",
                                color: "#FFFFFF",
                                padding: "0.75rem",
                                fontWeight: "bold",
                                "&:hover": {
                                    backgroundColor: "#EFFFEF",
                                    color: "#509458",
                                },
                                minWidth: "7rem",
                                borderRadius: "20px",
                            }}
                            onClick={() => {
                                setOpenIndustryOptions(true);
                            }}
                        >
                            Choose an Industry
                        </Button>
                    )}
                    {openIndustryOptions ? (
                        <OptionDialog
                            open={openIndustryOptions}
                            setOpen={setOpenIndustryOptions}
                            action={handleSaveIndustry}
                            options={["Finance", "Medical"]}
                        />
                    ) : (
                        <></>
                    )}
                    <Grid>
                        <SetBadge
                            title={`Your Personal Collection`}
                            description="Your own collection of vocabularies that can be acquired through chatting"
                            vocabs={industryVocabs}
                            action={() => {
                                setChosenSetTitle(`Your Personal Collection`);
                                setChosenSetDesc(
                                    "Your own collection of vocabularies that can be acquired through chatting"
                                );
                                const getVocab = async () => {
                                    const res = await APILoadVocab(
                                        chosenIndustry
                                    );
                                    if (res.data.vocabs.length === 0) {
                                        setEmptyVocab(true);
                                    }
                                    setIndustryVocabs(res.data.vocabs);
                                    setChosenVocabs(res.data.vocabs);
                                };
                                getVocab();
                                setChosenVocabs(industryVocabs);
                                setPreviewSet(true);
                                setChosenQnas([]); // no qnas for personal vocabs
                            }}
                            qnas={[]}
                        />
                        {sets.map((set, index) => {
                            return (
                                <SetBadge
                                    title={set.title}
                                    description={set.description}
                                    vocabs={set.vocabularies}
                                    qnas={qnaSets[index].questions}
                                    action={() => {
                                        setChosenSetDesc(set.description);
                                        setChosenSetTitle(set.title);
                                        setChosenVocabs(set.vocabularies);
                                        setChosenQnas(qnaSets[index].questions);
                                        setPreviewSet(true);
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </Box>
                <SetDialog
                    open={previewSet}
                    setOpen={setPreviewSet}
                    title={chosenSetTitle}
                    description={chosenSetDesc}
                    vocabs={chosenVocabs}
                    qnas={chosenQnas}
                />
                {emptyVocab ? (
                    <ErrorDialog
                        open={emptyVocab}
                        setOpen={setEmptyVocab}
                        title="Empty Collection"
                        text="Add more vocabulary to your collection by chatting!"
                        action={() => {
                            navigate("/chats");
                        }}
                        closeAction={() => {
                            setPreviewSet(false);
                        }}
                        actionText="Chat!"
                    />
                ) : (
                    <></>
                )}

                <CssBaseline />
            </Container>
        </Box>
    );
};

export default DashboardPage;
