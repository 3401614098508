import React, { useState } from "react";
import { Button, Stack } from "@mui/material";

export default function SwitchButtons({ texts, actions }) {
    const [chosenState, setChosenState] = useState(texts[0]);

    const handleClick = (clickedText, action) => {
        action(); // Execute the corresponding action
        setChosenState(clickedText); // Set the current state to reflect the chosen button
    };

    return (
        <React.Fragment>
            <Stack direction="row" spacing={0}>
                {" "}
                {/* Adjusted spacing here */}
                {texts.map((text, idx) => {
                    return (
                        <Button
                            onClick={() => handleClick(text, actions[idx])}
                            sx={
                                chosenState === text
                                    ? chosenStyle
                                    : originalStyle
                            }
                        >
                            {text}
                        </Button>
                    );
                })}
                {/* <Button
              onClick={() => handleClick(text3, action3)}
              sx={chosenState === text3 ? chosenStyle : originalStyle}
            >
              {text3}
            </Button> */}
            </Stack>
        </React.Fragment>
    );
}
const originalStyle = {
    color: "green",
    backgroundColor: "white",
    fontSize: "16px",
    borderRadius: 0,
};
const chosenStyle = {
    color: "white",
    backgroundColor: "green",
    fontSize: "16px",
    borderRadius: 0,
};
// #ECFFDC
