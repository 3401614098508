import React, { useState, forwardRef } from "react";
import { Button } from "@mui/material";

export const ToggleTextButton = forwardRef(
    ({ text, originalStyle, newStyle, action }, ref) => {
        const [old, setOld] = useState(true);

        return (
            <Button
                ref={ref}
                sx={old ? originalStyle : newStyle}
                onClick={() => {
                    setOld(!old);
                    action();
                }}
            >
                {text}
            </Button>
        );
    }
);
