import React, { useEffect, useState, useRef } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { signOut } from "../../constants/GoogleAuth";
import { styles } from "../../components/chat/styles";
import { ThreeDot } from "react-loading-indicators";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Avatar,
    Container,
    Typography,
    CssBaseline,
    Box,
    Toolbar,
    ThemeProvider,
    IconButton,
    List,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Button,
} from "@mui/material";
import { Drawer, AppBar } from "../../components/nav/appBar";
import { getAuth } from "firebase/auth";
import { TextInput } from "../../components/chat/textInput";
import Message from "../../components/chat/message";
import {
    APISendMessage,
    APIGetFeedback,
    APIInitiateChat,
    APISuggest,
    APIInitiateVocabs,
    APIPersonalChat,
} from "../../actions/api";
import { useStore } from "../../constants/store/store";
import { useNavigate, useLocation } from "react-router-dom";
import { TextOptions } from "../../components/chat/textOptions";
import { APIStoreVocab, APIStoreChat } from "../../actions/APIstoreChat";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import { APILoadVocab } from "../../actions/APIstoreChat";
import { toast } from "react-toastify";

const ChatPage = () => {
    const scrollRef = useRef(null);
    const [userInput, setUserInput] = useState("");
    const [chosenIndustry, setChosenIndustry] = useState(
        useStore((state) => state.industry)
    );
    const [chatHistory, setChatHistory] = useState([]); //displays the chat
    const [chatInitiated, setChatInitiated] = useState(false); //true or false when the chat is started
    const [open, setOpen] = useState(false);
    const [botLoading, setBotLoading] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [attempsLeft, setAttemptsLeft] = useState(10);
    const [userName, setUserName] = useState("");
    const [vocabs, setVocabs] = useState([]);
    const { setIsAuthorized, setToken } = useStore();
    const navigate = useNavigate();
    const location = useLocation(); // Use the useLocation hook to access route state
    const auth = getAuth();
    const chatInitiatedRef = useRef(false); // Add a ref to track initiation status

    useEffect(() => {
        if (location.state?.initiateChat && !chatInitiatedRef.current) {
            InitiateChat(); // Call the initiation function if the state indicates so
        }

        async function getUserName() {
            const user = auth.currentUser;
            if (user) {
                setUserName(user.displayName);
                const refreshedToken = await user.getIdToken();
                setToken(refreshedToken);
            } else {
                await auth.signOut();
            }
        }
        getUserName();
        async function scrollToBottom() {
            await new Promise((resolve) => {
                let id = 0;
                setInterval(() => {
                    // can change the interval rate later
                    if (id < 5) {
                        if (scrollRef.current) {
                            scrollRef.current.scrollIntoView({
                                behaviour: "smooth",
                            });
                        }
                        ++id;
                    } else {
                        resolve(); // Resolve the promise
                    }
                }, 1000);
            });
        }
        scrollToBottom();
    }, [location.state]); //removed chatHistory and Botloading: [chatHistory, botLoading,
    const useSignout = async () => {
        await signOut();
        setIsAuthorized(false);
        setToken("");
        navigate("/");
    };

    const useSendMessage = async () => {
        setAttemptsLeft(attempsLeft - 1);
        setChatHistory((chatHistory) => [
            ...chatHistory,
            { content: userInput, role: "user" },
        ]);
        setBotLoading(true);
        const res = await APISendMessage(userInput);
        // add response to chat history
        setChatHistory((chatHistory) => [
            ...chatHistory,
            { content: res.data.response, role: "system" },
        ]);
        setBotLoading(false);
        setUserInput("");
    };

    const InitiateChat = async () => {
        if (chatInitiatedRef.current) return; // Prevent re-initiation
        setBotLoading(true);
        setChatInitiated(true);
        chatInitiatedRef.current = true; // Update the ref synchronously
        setAttemptsLeft(10);
        setVocabs([]);
        setChatHistory([]);
        let res; // Declare `res` outside the if-else block to increase its scope
        if (location.state?.vocabs) {
            //if its the personal vocab collection, load the users vocabs and randomly select one word for chat
            //console.log(location.state.title);
            if (location.state.title === "Your Personal Collection") {
                // Load vocab users
                const load = await APILoadVocab(chosenIndustry);
                console.log(load.data.vocabs);
                // Choose a random vocab word

                const randomIndex = Math.floor(
                    Math.random() * load.data.vocabs.length
                );
                const vocabEntry = load.data.vocabs[randomIndex];
                const randomWord = Object.keys(vocabEntry)[0];
                const randomDefinition = vocabEntry[randomWord];
                //console.log(randomWord);
                // console.log(randomDefinition);
                res = await APIPersonalChat(
                    chosenIndustry,
                    randomWord,
                    randomDefinition
                );
            } else {
                res = await APIInitiateVocabs(
                    chosenIndustry,
                    location.state.title
                );
            }
        } else {
            res = await APIInitiateChat(chosenIndustry);
        }
        //console.log(res);
        const receivedPrompt = res.data.random_prompt.replace(/\n/g, "\n");
        //audio:
        // const audioResponse = res.data.audio;
        // const audioSrc = `data:audio/mp3;base64,${audioResponse}`;
        // const audio = new Audio(audioSrc);
        // audio.play();

        setChatHistory([{ content: receivedPrompt, role: "system" }]);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setChatHistory((chatHistory) => [
            ...chatHistory,
            { content: res.data.response, role: "system" },
        ]);
        await new Promise((resolve) => setTimeout(resolve, 6000));
        setBotLoading(false);
    };

    const useGetFeedback = async () => {
        try {
            const storeChat = await APIStoreChat(chatHistory, "threadid");
        } catch (error) {
            console.log(error);
        }
        setBotLoading(true);
        setChatInitiated(false);

        setChatInitiated(false);

        var history = [];
        for (var i = 1; i < chatHistory.length; ++i) {
            const chat = chatHistory[i];
            if (chat.role === "user") {
                history.push(`You: ${chat.content}`);
            } else {
                history.push(`Bizlang: ${chat.content}`);
            }
        }
        const res = await APIGetFeedback(history);
        setChatHistory((chatHistory) => [
            ...chatHistory,
            { content: res.data.summary, role: "system" },
        ]);

        const spelling = res.data.spelling;
        const jargon_application = res.data.jargon_application;
        const relevance = res.data.relevance;
        const summary = res.data.summary;

        console.log(spelling);
        console.log(jargon_application);
        console.log(relevance);
        console.log(summary);
        if (summary != "You must say something first to receive feedback!") {
            showRatings(spelling, jargon_application, relevance, summary);
        }

        await new Promise((resolve) => setTimeout(resolve, 5000));
        setBotLoading(false);
        //add suggest words
        const vocabsRes = await APISuggest(history, chosenIndustry);
        // result format: {suggest: [[vocab, def], ...]}
        setVocabs(
            vocabsRes.data.suggest.map((vocab_pair) => {
                return `${vocab_pair[0]}: ${vocab_pair[1]}`;
            })
        );
    };

    //popup bar | want to show the ratings in a bar/chart format
    const showRatings = (spelling, jargon_application, relevance, summary) => {
        const ratingBar = (
            <div
                style={{
                    backgroundColor: "green",
                    color: "white",
                    padding: "10px",
                }}
            >
                <p>
                    Spelling: <span>{spelling}</span>
                </p>
                <p>
                    Jargon Application: <span>{jargon_application}</span>
                </p>
                <p>
                    Relevance: <span>{relevance}</span>
                </p>
                <p>Summary: {summary}</p>
            </div>
        );

        toast.info(ratingBar, {
            position: "top-center",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            style: {
                backgroundColor: "green",
                color: "white",
            },
        });
    };

    return (
        <ThemeProvider theme={styles}>
            <Box
                padding={0}
                sx={{
                    display: "flex",
                    maxHeight: "100vh",
                    justifyContent: "center",
                }}
            >
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px", // keep right padding when drawer closed
                            backgroundColor: "green",
                            display: "flex",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/home");
                            }}
                            sx={{
                                flexGrow: 1,
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginTop: "-12px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/home");
                                }}
                                src={BizLangIcon}
                                height={65}
                                width={100}
                            />
                            <Typography
                                variant="h6"
                                color="inherit"
                                noWrap
                                onClick={() => {
                                    navigate("/home");
                                }}
                                sx={{
                                    margin: "0",
                                    padding: "0",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "1.6rem",
                                    fontFamily: "monospace",
                                    paddingTop: "15px",
                                    marginRight: "20px",
                                }}
                            >
                                BIZLANG
                            </Typography>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                            backgroundColor: "green",
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <React.Fragment>
                            <ListItemButton>
                                <ListItemAvatar
                                    style={{ padding: 0, margin: 0 }}
                                >
                                    <Avatar
                                        alt={userName}
                                        src={userName}
                                        style={{
                                            padding: 0,
                                            margin: 0,
                                            width: "2rem",
                                            height: "2rem",
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={userName} />
                            </ListItemButton>
                            <ListItemButton onClick={useSignout}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="Sign out" />
                            </ListItemButton>
                        </React.Fragment>
                        {/* {mainListItems} */}
                        <Divider sx={{ my: 1 }} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        justifyContent: "center",
                        maxWidth: "100%",
                    }}
                    marginRight={0}
                    padding={0}
                >
                    <Toolbar />
                    {open && window.innerWidth < 720 ? (
                        <></>
                    ) : (
                        <Container
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                mr: 0,
                                width: "100%",
                                mt: 6,
                                mb: 2,
                                p: 0,
                                ml: 0,
                                maxHeight: "93%",
                                height: "93%",
                                justifyContent: "center",
                            }}
                            disableGutters
                        >
                            <Container
                                sx={{
                                    width: "100%",
                                    margin: 0,
                                    justifyContent: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "87%",
                                    height: "87%",
                                    p: 0,
                                }}
                                disableGutters
                            >
                                {chatHistory.length > 0 ? (
                                    <Container
                                        maxWidth="xl"
                                        sx={{
                                            mt: 4,
                                            ml: 0,
                                            mb: 2,
                                            maxHeight: "25%",
                                            p: 0,
                                            MuiCssBaseline: {
                                                "@global": {
                                                    "*::-webkit-scrollbar": {
                                                        width: "3px",
                                                    },
                                                    "*::-webkit-scrollbar-track":
                                                        {
                                                            background:
                                                                "#E4EFEF",
                                                        },
                                                    "*::-webkit-scrollbar-thumb":
                                                        {
                                                            background:
                                                                "#1D388F61",
                                                            borderRadius: "2px",
                                                        },
                                                },
                                            },
                                        }}
                                        disableGutters
                                    >
                                        <Message
                                            message={chatHistory[0].content}
                                            isBot={
                                                chatHistory[0].role === "system"
                                            }
                                            botLoading={botLoading}
                                            setBotLoading={setBotLoading}
                                        />
                                    </Container>
                                ) : (
                                    <Container
                                        maxWidth="xl"
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                            ml: 0,
                                            maxHeight: "20%",
                                        }}
                                        disableGutters
                                    ></Container>
                                )}

                                <Container
                                    maxWidth="xl"
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                        p: 0,
                                        ml: 0,
                                        maxHeight: "75%",
                                        height: "75%",
                                        overflowY: "auto",

                                        "&::-webkit-scrollbar": {
                                            width: "5px",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            background: "#f1f1f1",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            background: "#9BABB8",
                                            borderRadius: "5px",
                                        },
                                    }}
                                    disableGutters
                                >
                                    <List styles={{ maxHeight: "75%", p: 0 }}>
                                        {chatHistory.map((message, index) => {
                                            if (index !== 0) {
                                                return (
                                                    <Message
                                                        message={
                                                            message.content
                                                        }
                                                        isBot={
                                                            message.role ===
                                                            "system"
                                                        }
                                                        delay={
                                                            message.role ===
                                                            "system"
                                                                ? chatHistory[
                                                                      index - 1
                                                                  ].content
                                                                      .length *
                                                                  30
                                                                : 0
                                                        }
                                                    />
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                        {vocabs.length > 0 ? (
                                            <TextOptions
                                                vocabs={vocabs}
                                                key="vocabs"
                                                action={(chosenVocabs) => {
                                                    // need to change later
                                                    setVocabs([]);
                                                }}
                                                chosenIndustry={chosenIndustry}
                                            ></TextOptions>
                                        ) : (
                                            <></>
                                        )}
                                        {botLoading ? (
                                            <ThreeDot
                                                variant="pulsate"
                                                color="#218aff"
                                                size="medium"
                                                text=""
                                                textColor=""
                                                sx={{ marginRight: "5em" }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <div ref={scrollRef} />
                                    </List>
                                </Container>
                            </Container>

                            <Container
                                spacing={3}
                                sx={{
                                    width: "100%",
                                    m: 0,
                                    p: 0,
                                    justifyContent: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    maxHeight: "13%",
                                }}
                                disableGutters
                            >
                                <Container
                                    spacing={3}
                                    sx={{
                                        width: "100%",
                                        m: 0,
                                        p: 0,
                                        justifyContent: "center",
                                        display: "flex",
                                        paddingRight: "1.2rem",
                                    }}
                                    disableGutters
                                >
                                    {!chatInitiated ? (
                                        <Button
                                            sx={{ marginRight: "0.5rem" }}
                                            onClick={InitiateChat}
                                        >
                                            Start Chat
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                sx={{ marginLeft: "0.5rem" }}
                                                onClick={useGetFeedback}
                                                disabled={botLoading}
                                            >
                                                Feedback
                                            </Button>
                                        </>
                                    )}
                                </Container>
                                {chatInitiated && attempsLeft > 0 ? (
                                    <TextInput
                                        textAction={setUserInput}
                                        btnAction={useSendMessage}
                                        userInput={userInput}
                                        attempsLeft={attempsLeft}
                                        disabled={botLoading}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Container>
                        </Container>
                    )}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default ChatPage;
