import React, { useState, useRef } from "react";
import {
    Typography,
    IconButton,
    Card,
    CardActionArea,
    CardContent,
    Box,
    RadioGroup,
    Radio,
    FormControlLabel,
    Button,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

export default function QnACard({
    question,
    options,
    answer,
    nextAction = () => {},
    prevAction = () => {},
    action = () => {},
    answerable = true,
    scoreAction = () => {},
    showAnswer = false,
}) {
    const [showAns, setShowAns] = useState(showAnswer);
    const [value, setValue] = React.useState("");
    const [borderColor, setBorderColor] = React.useState("white");
    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };
    const radioGroupRef = useRef(null);
    const handleShowAnswer = () => {
        if (value === answer) {
            scoreAction();
            setBorderColor("green");
        } else {
            setBorderColor("red");
        }
        setShowAns(true);
        action();
    };

    return (
        <React.Fragment>
            <Card
                elevation={10}
                sx={{
                    display: "flex",
                    "@media (min-width: 1200px)": {
                        minWidth: "40rem",
                    },
                    maxWidth: "100%",
                    minWidth: "100%",
                    marginBottom: "2rem",
                    border: 2,
                    borderColor: { borderColor },
                }}
            >
                <CardActionArea
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "10%",
                    }}
                    onClick={() => {
                        prevAction();
                        setBorderColor("black");
                        radioGroupRef.current.value = "";
                        if (showAnswer) {
                            setShowAns(true);
                        } else {
                            setShowAns(false);
                        }
                    }}
                >
                    <IconButton
                        aria-label="back"
                        sx={{}}
                        onClick={() => {
                            prevAction();
                            setBorderColor("black");
                            radioGroupRef.current.value = "";
                            if (showAnswer) {
                                setShowAns(true);
                            } else {
                                setShowAns(false);
                            }
                        }}
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                </CardActionArea>

                <CardContent sx={{ minWidth: "80%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <CardContent sx={{ flex: "1 0 auto" }}>
                            <Typography
                                component="div"
                                variant="h5"
                                sx={{ marginBottom: "5px" }}
                            >
                                {question}
                            </Typography>
                            <RadioGroup
                                name="quiz"
                                value={value}
                                onChange={handleRadioChange}
                                ref={radioGroupRef}
                            >
                                {options.map((option) => {
                                    return (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={
                                                <Radio
                                                    sx={{ color: "green" }}
                                                />
                                            }
                                            label={option}
                                            sx={{
                                                borderRadius: "5px",
                                                marginBottom: "2px",
                                                borderColor: "green",
                                                border: 1,
                                            }}
                                        />
                                    );
                                })}
                            </RadioGroup>
                            {!showAns ? (
                                <Button
                                    sx={{
                                        margin: "2px",
                                        color: "black",
                                        border: 1,
                                        borderRadius: "5px",
                                        marginTop: "10px",
                                        borderColor: "green",
                                    }}
                                    onClick={handleShowAnswer}
                                    disabled={answerable}
                                >
                                    Show Answer
                                </Button>
                            ) : (
                                <Typography sx={{ fontWeight: "bold" }}>
                                    {" "}
                                    Correct Answer: {answer}
                                </Typography>
                            )}
                        </CardContent>
                    </Box>
                </CardContent>

                <CardActionArea
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "10%",
                    }}
                    onClick={() => {
                        nextAction();
                        setBorderColor("black");
                        radioGroupRef.current.value = "";
                        setShowAns(false);
                    }}
                >
                    <IconButton
                        aria-label="back"
                        sx={{}}
                        onClick={() => {
                            nextAction();
                            setBorderColor("black");
                            radioGroupRef.current.value = "";
                            setShowAns(false);
                        }}
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}
