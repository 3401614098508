import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import FlashcardsIcon from "./../../assets/flash-cards.png";
import { useNavigate } from "react-router-dom";

export default function SetDialog({
    open,
    setOpen,
    title,
    description,
    vocabs,
    qnas,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const navigate = useNavigate();

    const handleChat = () => {
        // navigate to chat page with vocabs
        setOpen(false);
        navigate("/chats", { state: { initiateChat: true, vocabs: true, title: title  } });
    };
    const handleStudy = () => {
        // navigate to study page with vocabs
        if (vocabs.length !== 0) {
            navigate("/study", { state: [vocabs, qnas] });
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    sx={{
                        backgroundColor: "#FAF9F8",
                        minHeight: "35rem",
                        minWidth: "30rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <img
                        src={FlashcardsIcon}
                        style={{
                            maxHeight: "20rem",
                            maxWidth: "20rem",
                            marginTop: "5px",
                        }}
                    ></img>
                    <Typography sx={{ fontWeight: "bold", fontSize: "28px" }}>
                        {title}
                    </Typography>
                    <DialogContentText>{description}</DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        backgroundColor: "#FAF9F8",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        sx={{
                            color: "#000000",
                            backgroundColor: "#FFFFFF",
                            fontWeight: "bold",
                            border: "1px solid #000000",
                            borderRadius: "30px",
                            minWidth: "5rem",
                            padding: "10px",
                            paddingRight: "25px",
                            paddingLeft: "25px",
                            marginBottom: "5px",
                        }}
                        onClick={handleStudy}
                    >
                        Study
                    </Button>
                    <Button
                        sx={{
                            color: "#FFFFFA",
                            backgroundColor: "#FFA500",
                            fontWeight: "bold",
                            border: "1px solid #FFA500",
                            borderRadius: "30px",
                            minWidth: "5rem",
                            padding: "10px",
                            paddingRight: "25px",
                            paddingLeft: "25px",
                            "&:hover": {
                                backgroundColor: "#EE9400",
                            },
                            marginBottom: "5px",
                        }}
                        onClick={handleChat}
                    >
                        Chat
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
