import React, { useState } from "react";
import {
    getAuth,
    verifyPasswordResetCode,
    confirmPasswordReset,
} from "firebase/auth";
import {
    Container,
    Typography,
    TextField,
    Button,
    CssBaseline,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
} from "@mui/material";
import { styles } from "./styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import BizLangIcon from "../../assets/bizlang_logo.svg";

const ActionPage = () => {
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const auth = getAuth();
    const [success, setSuccess] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const actionCode = searchParams.get("oobCode");
    const mode = searchParams.get("mode");

    const useResetPW = async () => {
        verifyPasswordResetCode(auth, actionCode)
            .then((email) => {
                const accountEmail = email;
                // Save the new password.
                confirmPasswordReset(auth, actionCode, password)
                    .then((resp) => {
                        setSuccess(true);
                    })
                    .catch((error) => {
                        // Error occurred during confirmation. The code might have expired or the
                        // password is too weak.
                        setError("Password is too weak. Try again");
                    });
            })
            .catch((error) => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                setError("The link has expired. Try sending a new email");
            });
    };
    return (
        <ThemeProvider theme={styles}>
            <Container
                component="main"
                maxWidth="xl"
                style={{ padding: 0, height: "100%", margin: 0, flexGrow: 1 }}
            >
                <CssBaseline />
                <AppBar
                    position="relative"
                    style={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                marginTop: "-10px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                src={BizLangIcon}
                                height={75}
                                width={100}
                            />
                        </Typography>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "0",
                                padding: "0",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.6rem",
                                fontFamily: "monospace",
                                paddingTop: "15px",
                            }}
                        >
                            BIZLANG
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="xs">
                    <CssBaseline />
                    {mode === "resetPassword" ? (
                        <Box
                            sx={{
                                marginTop: 8,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Typography component="h1" variant="h4">
                                Reset your password
                            </Typography>
                            {success ? (
                                <Box component="form" noValidate sx={{ mt: 5 }}>
                                    <Typography
                                        style={{
                                            color: "black",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Go log in with your new password!
                                    </Typography>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {
                                            navigate("/");
                                        }}
                                    >
                                        Return to Login
                                    </Button>
                                </Box>
                            ) : (
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="password"
                                        label="New Password"
                                        name="password"
                                        autoFocus
                                        onChange={(e) => {
                                            setPassword(e.target.value);
                                            setError("");
                                        }}
                                    />

                                    {!(error === "") ? (
                                        <Typography
                                            style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                margin: 0,
                                            }}
                                        >
                                            {error}
                                        </Typography>
                                    ) : (
                                        <></>
                                    )}

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                        onClick={useResetPW}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    ) : (
                        <></>
                    )}
                </Container>
            </Container>
        </ThemeProvider>
    );
};

export default ActionPage;
