// Login.js
import React, { useEffect, useState } from "react";
import {
    getAuth,
    signInWithEmailAndPassword,
    onAuthStateChanged,
} from "firebase/auth";
import { useStore } from "../../constants/store/store";
import { GoogleLogin } from "../../constants/GoogleAuth";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    CssBaseline,
    Link,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
    IconButton,
    Avatar,
} from "@mui/material";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "./../../assets/google-icon.png";
import LoadingOverlay from "../../components/misc/loading";
import { APIStoreUser } from "../../actions/APIstoreChat";
import Message from "../../components/chat/message";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const { setIsAuthorized, setToken } = useStore();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const auth = getAuth();

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            // set token
            if (user) {
                const token = await user.getIdToken();
                setToken(token);
                // await APIStoreUser();
                setIsAuthorized(true);
                navigate("/home");
            } else {
                setLoading(false);
                await auth.signOut();
            }
        });
    }, []);
    const useLogin = async () => {
        if (email === "") {
            setError("Login error: email is empty");
        } else if (password === "") {
            setError("Login error: password is empty");
        } else {
            setLoading(true);
            try {
                const result = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        setIsAuthorized(true);
                        const token = await user.getIdToken();
                        setToken(token);
                        navigate("/home");
                    }
                });
            } catch (error) {
                setLoading(false);
                setError("Login error: Invalid credentials. Try again.");
            }
        }
    };

    const useGoogleLogin = async () => {
        setLoading(true);
        await GoogleLogin();
    };

    return (
        <ThemeProvider theme={styles}>
            {loading ? <LoadingOverlay /> : <></>}
            <Container
                component="main"
                style={{
                    padding: 0,
                    height: "100%",
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    maxWidth: "100%",
                }}
            >
                <CssBaseline />
                <AppBar
                    position="relative"
                    style={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                marginTop: "-10px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                src={BizLangIcon}
                                height={75}
                                width={100}
                            />
                        </Typography>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "0",
                                padding: "0",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.6rem",
                                fontFamily: "monospace",
                                paddingTop: "15px",
                            }}
                        >
                            BIZLANG
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container
                    component="container"
                    maxWidth="xs"
                    style={{ flexGrow: 1 }}
                >
                    <CssBaseline />

                    <Box
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h4">
                            Sign in to practice your industry's lingo
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                            />
                            {!(error === "") ? (
                                <Typography
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        margin: 0,
                                    }}
                                >
                                    {error}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            <Grid container>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Link
                                        variant="body2"
                                        onClick={() => {
                                            navigate("/forgotPassword");
                                        }}
                                    >
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={useLogin}
                            >
                                Sign In
                            </Button>
                            <Typography
                                style={{
                                    color: "black",
                                    fontWeight: "normal",
                                    margin: 0,
                                }}
                            >
                                or
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2, padding: 0 }}
                                style={{
                                    backgroundColor: "#649eff",
                                    justifyContent: "center",
                                }}
                                onClick={useGoogleLogin}
                            >
                                <IconButton
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <Avatar
                                        src={GoogleIcon}
                                        alt="Custom Icon"
                                        style={{
                                            height: "2.5rem",
                                            width: "2.5rem",
                                            margin: 0,
                                            marginRight: 10,
                                            backgroundColor: "white",
                                            padding: 8,
                                        }}
                                    />
                                </IconButton>
                                Sign in with Google
                            </Button>

                            <Grid item>
                                <Link
                                    variant="body1"
                                    onClick={() => {
                                        navigate("/signup");
                                    }}
                                >
                                    {"Don't have an account? Sign Up!"}
                                </Link>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
                <Box
                    sx={{
                        flexGrow: 0,
                        justifyContent: "center",
                        display: "flex",
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar
                        style={{
                            backgroundColor: "green",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="caption" color="white">
                            Copyright ©2024 BIZLANG. All Rights Reserved
                        </Typography>
                    </Toolbar>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
