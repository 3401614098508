import React, { useEffect, useState } from "react";
import { Box, ListItemText, IconButton } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
const Message = ({ message, isBot, delay = 0 }) => {
    const [isTyping, setIsTyping] = useState(false);
    const [typingMessageLocal, setTypingMessageLocal] = useState("");
    const [id, setId] = useState(0);
    const [botLoading, setBotLoading] = useState(false);

    const speakText = (text) => {
        if ("speechSynthesis" in window) {
            const msg = new SpeechSynthesisUtterance();
            msg.text = text;
            msg.lang = "en-US"; // You can change this to match the language of your text
            window.speechSynthesis.speak(msg);
        } else {
            console.error("Your browser does not support speech synthesis.");
        }
    };

    const simulateTyping = async (message) => {
        // if delay is set to non-zero, delay the start of typing simulation by that amount of ms
        if (delay > 0) {
            await new Promise((resolve) => setTimeout(resolve, delay));
        }
        setBotLoading(true); // Set botLoading to true
        setIsTyping(true); // Set isTyping to true when animation starts

        await new Promise((resolve) => {
            let id = 0;
            setInterval(() => {
                if (id < message.length) {
                    setTypingMessageLocal(
                        message.substring(0, id) + message[id]
                    );
                    setId(++id);
                } else {
                    resolve(); // Resolve the promise
                    setBotLoading(false); // Set botLoading to false after typing is complete
                }
            }, 25);
        });
    };
    useEffect(() => {
        simulateTyping(message);
    }, [message]);
    return (
        <React.Fragment>
            {isTyping ? (
                <Box
                    sx={{
                        backgroundColor: !isBot ? "#218aff" : "#9BABB8",
                        borderRadius: 6,
                        maxWidth: "90%",
                        width: `${typingMessageLocal.length * 1.5 + 14}%`,
                        pt: 0.5,
                        pb: 0.5,
                        mb: 1,
                        mr: isBot ? `10%` : 0,
                        ml: isBot
                            ? 0
                            : typingMessageLocal.length * 1.5 + 14 < 98
                            ? `${98 - typingMessageLocal.length * 1.5 - 14}%`
                            : "8%",
                        flexDirection: "row",
                        display: "flex",
                    }}
                >
                    <ListItemText
                        primary={typingMessageLocal}
                        sx={{
                            color: "white",
                            fontSize: 24,
                            fontFamily: "sans-serif",
                            left: 0,
                            p: 1,
                            width: "100%",
                        }}
                        primaryTypographyProps={{
                            style: {
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                            },
                        }}
                    />
                    <IconButton
                        onClick={() => {
                            speakText(message);
                        }}
                        sx={{ padding: 0, margin: 0 }}
                    >
                        <PlayArrowIcon />
                    </IconButton>
                </Box>
            ) : (
                <></>
            )}
        </React.Fragment>
    );
};
export default Message;
