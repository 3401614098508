import React, { useState, useEffect, useRef } from "react";
import {
    Container,
    CssBaseline,
    Box,
    Avatar,
    Grid,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonWithTopIcon from "./../../components/misc/buttonWithTopIcon";
import { getAuth } from "firebase/auth";
import ChatIcon from "./../../assets/bubble-chat.png";
import NewsIcon from "./../../assets/world.png";
import WordleIcon from "./../../assets/wordle.png";
import FlashcardsIcon from "./../../assets/flash-cards.png";
import OptionDialog from "../../components/SetOptionsUI/optionDialog";
import { useStore } from "../../constants/store/store";
import { APILoadUser } from "../../actions/APIstoreChat";

const LandingPage = () => {
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [chosenIndustry, setChosenIndustry] = useState(
        useStore((state) => state.industry)
    );
    const [openIndustryOptions, setOpenIndustryOptions] = useState(false);
    const { setIndustry, setToken, token } = useStore();
    const chooseIndustryRef = useRef(null);

    const handleSaveIndustry = (val) => {
        console.log(val);
        setChosenIndustry(val);
        setIndustry(val);
    };
    const auth = getAuth();

    useEffect(() => {
        async function loadUser() {
            const user = auth.currentUser;
            if (user) {
                setUserName(user.displayName);
                const refreshedToken = await user.getIdToken();
                await setToken(refreshedToken);
                if (token !== "") {
                    try {
                        const user = await APILoadUser();
                        if (!user.data.username) {
                            navigate("/personalize");
                        }
                    } catch (e) {
                        console.log("Error loading user in landing page");
                        navigate("/personalize");
                    }
                }
            } else {
                await auth.signOut();
            }
        }
        loadUser();
    }, []);

    return (
        <Box
            component="main"
            style={{
                display: "flex",
                padding: 0,
                height: "100%",
                margin: 0,
                flexGrow: 1,
                width: "100%",
                minWidth: "100%",
            }}
        >
            <Container
                component="container"
                sx={{
                    background: "linear-gradient(to bottom, #FFFFFF, #74D680)",
                    height: "100%",
                    width: "100%",
                    minWidth: "100%",
                }}
            >
                <CssBaseline />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                    }}
                >
                    <Avatar
                        alt={userName}
                        sx={{
                            width: "10em",
                            height: "10em",
                            border: "5px solid #378B29",
                            marginBottom: "50px",
                            boxShadow: "18px 18px 36px #509458",
                            backgroundColor: "#FFFFFF",
                            color: "#509458",
                        }}
                    />

                    <Grid
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        container
                    >
                        <Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item
                        >
                            <ButtonWithTopIcon
                                src={WordleIcon}
                                text="Wordle"
                                action={() => {
                                    navigate("/wordle");
                                }}
                            />
                        </Grid>
                        <Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item
                        >
                            <ButtonWithTopIcon
                                src={ChatIcon}
                                text="Chat"
                                action={() => {
                                    if (chosenIndustry !== "") {
                                        navigate("/chats");
                                    } else {
                                        if (chooseIndustryRef.current) {
                                            chooseIndustryRef.current.click();
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item
                        >
                            <ButtonWithTopIcon
                                src={FlashcardsIcon}
                                text="Flash Cards"
                                action={() => {
                                    if (chosenIndustry !== "") {
                                        navigate("/flashcards");
                                    } else {
                                        if (chooseIndustryRef.current) {
                                            chooseIndustryRef.current.click();
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            item
                        >
                            <ButtonWithTopIcon
                                src={NewsIcon}
                                text="News"
                                action={() => {
                                    if (chosenIndustry !== "") {
                                        navigate("/news");
                                    } else {
                                        if (chooseIndustryRef.current) {
                                            chooseIndustryRef.current.click();
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    {chosenIndustry !== "" ? (
                        <Button
                            sx={{
                                backgroundColor: "#509458",
                                color: "#FFFFFF",
                                padding: "0.5rem",
                                fontWeight: "bold",
                                "&:hover": {
                                    backgroundColor: "#EFFFEF",
                                    color: "#509458",
                                },
                                minWidth: "7rem",
                                borderRadius: "20px",
                            }}
                            onClick={() => {
                                setOpenIndustryOptions(true);
                            }}
                        >
                            {chosenIndustry}
                        </Button>
                    ) : (
                        <Button
                            sx={{
                                backgroundColor: "#509458",
                                color: "#FFFFFF",
                                padding: "0.75rem",
                                fontWeight: "bold",
                                "&:hover": {
                                    backgroundColor: "#EFFFEF",
                                    color: "#509458",
                                },
                                minWidth: "7rem",
                                borderRadius: "20px",
                            }}
                            onClick={() => {
                                setOpenIndustryOptions(true);
                            }}
                            ref={chooseIndustryRef}
                        >
                            Choose an Industry
                        </Button>
                    )}
                    {openIndustryOptions ? (
                        <OptionDialog
                            open={openIndustryOptions}
                            setOpen={setOpenIndustryOptions}
                            action={handleSaveIndustry}
                            options={["Business", "Finance", "Medical"]}
                        />
                    ) : (
                        <></>
                    )}
                </Box>
            </Container>
        </Box>
    );
};

export default LandingPage;
