import React, { useState, useRef } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

export default function ErrorDialog({
    action,
    open,
    setOpen,
    text,
    title,
    actionText,
    closeAction,
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleClose = () => {
        closeAction();
        setOpen(false);
    };
    const handleSave = () => {
        action();
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    minHeight: "20rem",
                    minWidth: "20rem",
                }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    sx={{
                        paddingRight: "5rem",
                        backgroundColor: "#FAF9F8",
                        fontWeight: "bold",
                        color: "#353935",
                    }}
                >
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ backgroundColor: "#FAF9F8" }}>
                    <Typography>{text}</Typography>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#FAF9F8" }}>
                    <Button
                        sx={{
                            color: "#FFFFFF",
                            backgroundColor: "#509458",
                            fontWeight: "bold",
                        }}
                        onClick={handleSave}
                    >
                        {actionText}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
