import React, { useState, useEffect } from "react";
import { Container, CssBaseline, Box, Grid, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import FlashCard from "../../components/flashcards/card";
import QnACard from "../../components/flashcards/qnaCard";
import SwitchButtons from "../../components/misc/switchButton";
import GaugeComponent from "react-gauge-component";

const FlashCardsPage = ({ navigation, route }) => {
    const [order, setOrder] = useState(0);
    const [qnaOrder, setQnaOrder] = useState(0);
    const [flashcardsStudy, setFlashcardsStudy] = useState(true);
    const location = useLocation();
    const vocabs = location.state[0];
    const [qnaScore, setQnaScore] = useState(0);
    const qnas = location.state[1];
    const [qAnswered, setQAnswered] = useState(0);

    return (
        <Box
            component="main"
            style={{
                display: "flex",
                padding: 0,
                height: "100%",
                margin: 0,
                flexGrow: 1,
            }}
        >
            <CssBaseline />
            <Container
                maxWidth="xl"
                sx={{
                    background: "linear-gradient(to bottom, #FFFFFF, #74D680)",
                    height: "100vh",
                }}
            >
                <Box
                    display="flex"
                    justifyContent="space-evenly"
                    alignItems="center"
                    minHeight="100vh"
                    flexDirection="column"
                >
                    {qnas.length === 0 ? (
                        <SwitchButtons
                            texts={["Flashcards"]}
                            actions={[
                                () => {
                                    setFlashcardsStudy(true);
                                },
                            ]}
                        />
                    ) : (
                        <SwitchButtons
                            texts={["Flashcards", "Questions"]}
                            actions={[
                                () => {
                                    setFlashcardsStudy(true);
                                },
                                () => {
                                    setFlashcardsStudy(false);
                                },
                            ]}
                        />
                    )}

                    {flashcardsStudy ? (
                        <FlashCard
                            word={Object.keys(vocabs[order]).toString()}
                            definition={
                                vocabs[order][Object.keys(vocabs[order])]
                            }
                            nextAction={() => {
                                if (order < vocabs.length - 1) {
                                    setOrder(order + 1);
                                }
                            }}
                            prevAction={() => {
                                if (order > 0) {
                                    setOrder(order - 1);
                                }
                            }}
                        />
                    ) : (
                        <>
                            <GaugeComponent
                                maxValue={qnas.length}
                                value={qnaScore}
                                arc={{
                                    subArcs: [
                                        {
                                            limit: qnas.length / 3,
                                            color: "#EA4228",
                                        },
                                        {
                                            limit: (2 * qnas.length) / 3,
                                            color: "#F5CD19",
                                        },
                                        { color: "#5BE12C" },
                                    ],
                                }}
                            />
                            <QnACard
                                question={qnas[qnaOrder].question}
                                options={qnas[qnaOrder].options}
                                answer={qnas[qnaOrder].answer}
                                nextAction={() => {
                                    if (qnaOrder < qnas.length - 1) {
                                        setQnaOrder(qnaOrder + 1);
                                    } else {
                                        setQnaOrder(0);
                                        setQnaScore(0);
                                        setQAnswered(0);
                                    }
                                }}
                                prevAction={() => {
                                    if (qnaOrder > 0) {
                                        setQnaOrder(qnaOrder - 1);
                                    }
                                }}
                                scoreAction={() => {
                                    setQnaScore(qnaScore + 1);
                                }}
                                answerable={
                                    qAnswered + 1 === qnaOrder ? true : false
                                }
                                action={() => {
                                    setQAnswered(qAnswered + 1);
                                }}
                                showAnswer={qnaOrder < qAnswered ? true : false}
                            />
                        </>
                    )}
                </Box>
                <CssBaseline />
            </Container>
        </Box>
    );
};

export default FlashCardsPage;
