import React from "react";
import { Typography, Avatar, Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ButtonWithTopIcon = ({ action, src, text }) => {
    return (
        <Box
            onClick={() => action()}
            sx={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                margin: "1rem",
            }}
        >
            <Avatar
                alt="icon"
                src={src}
                sx={{
                    width: "6em",
                    height: "6em",
                    border: "2px solid #378B29",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "18px 18px 25px #509458",
                    "&:hover": {
                        boxShadow: "25px 25px 30px #509458",
                    },
                }}
            />

            <Typography
                onClick={() => action()}
                sx={{
                    marginTop: "20px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    fontSize: "28",
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default ButtonWithTopIcon;
