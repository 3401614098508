import React, { useState } from "react";
import { Typography, Card, CardContent, CardMedia, Box } from "@mui/material";
import FlashCard from "./../../assets/flash-cards.png";

import { useNavigate } from "react-router-dom";

export default function SetBadge({
    title,
    description,
    action = () => {},
    vocabs,
    qnas,
}) {
    const navigate = useNavigate();

    const handleChat = () => {
        // navigate to chat page with vocabs
        navigate("/chats", { state: { initiateChat: true, vocabs: true } });
    };
    const handleStudy = () => {
        // navigate to study page with vocabs
        if (vocabs.length !== 0) {
            navigate("/study", { state: [vocabs, qnas] });
        }
    };
    return (
        <Card
            sx={{
                display: "flex",
                "@media (min-width: 1200px)": {
                    minWidth: "40rem",
                },
                maxWidth: "100%",
                minWidth: "100%",
                marginBottom: "2rem",
            }}
            onClick={() => {
                action();
            }}
        >
            <CardMedia
                component="img"
                sx={{ width: 120 }}
                image={FlashCard}
                alt="image"
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "stretch",
                }}
            >
                <CardContent sx={{ margin: 0 }}>
                    <Typography component="div" variant="h5">
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        {description}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
}
