// Import required libraries
import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDSdeT0OnCDhEWweSpWe2w0XpKyNPYuLBs",
    authDomain: "bizlang-83e55.firebaseapp.com",
    projectId: "bizlang-83e55",
    storageBucket: "bizlang-83e55.appspot.com",
    messagingSenderId: "407527358204",
    appId: "1:407527358204:web:c0ecfd8fcf98e49a2ba179",
    measurementId: "G-L3XWH1GL52",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

getAnalytics();

// Render App component into the DOM
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <HashRouter>
            <App />
        </HashRouter>
    </React.StrictMode>
);

// Optional: Web vitals reporting
reportWebVitals();
