import { create } from "zustand";
import { initialState, AuthSlice, ChatSlice, UserSlice } from "./slices";
import { persist, createJSONStorage } from "zustand/middleware";

export const useStore = create(
    persist(
        (...a) => ({
            ...AuthSlice(...a),
            ...ChatSlice(...a),
            ...UserSlice(...a),
        }),

        {
            name: "user-storage",
            storage: createJSONStorage(() => localStorage),
        }
    )
);
