import {
    getAuth,
    signInWithRedirect,
    getRedirectResult,
    GoogleAuthProvider,
} from "firebase/auth";
import { APIStoreUser } from "../actions/APIstoreChat";

async function GoogleLogin() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        prompt: "select_account",
    });
    await signInWithRedirect(auth, provider);
    getRedirectResult(auth)
        .then(async(result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            await APIStoreUser()       })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode + ": " + errorMessage);
        });
}

async function signOut() {
    const auth = getAuth();
    await auth.signOut();
}

export { GoogleLogin, signOut };
