import React, { useState, useEffect } from "react";
import { TextField, Button, ThemeProvider, Box } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import StopIcon from "@mui/icons-material/Stop";
import { styles } from "./styles";

export const TextInput = ({
    attempsLeft,
    userInput,
    textAction,
    btnAction,
    disabled = false,
}) => {
    const [recording, setRecording] = useState(false);
    var recognition;
    const [recAllowed, setRecAllowed] = useState(true);

    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
        var recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
    } else {
        setRecAllowed(false);
    }
    const startSpeechRecognition = () => {
        // var recognition = new (window.SpeechRecognition ||
        //     window.webkitSpeechRecognition)();
        recognition.lang = "en-US";
        recognition.continuous = true;
        recognition.onstart = () => {
            setRecording(true);
        };

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            textAction(userInput + " " + transcript);
            userInput += transcript;
        };
        recognition.onspeechend = function () {
            recognition.continuous = true;
            recognition.stop();
        };

        recognition.onerror = (event) => {
            recognition.continuous = true;
            recognition.stop();
        };
        recognition.onend = (event) => {
            recognition.continuous = false;
            recognition.stop();
        };

        recognition.start();
    };

    const stopSpeechRecognition = () => {
        recognition.continuous = false;
        recognition.stop();
        recognition.abort();
        setRecording(false);
        // recognition = null;
    };

    return (
        <ThemeProvider theme={styles}>
            <Box
                component="form"
                noValidate
                sx={{
                    mt: 1,
                    display: "flex",
                    flexDirection: "row",
                    pl: 0,
                    ml: 0,
                    mr: 0.5,
                    alignItems: "center",
                }}
            >
                <TextField
                    id="standard-text"
                    label="Your response"
                    placeholder={"You have " + attempsLeft + " attempts left"}
                    value={userInput}
                    fullWidth
                    multiline
                    onChange={(e) => {
                        textAction(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            textAction("");
                            btnAction();
                        }
                    }}
                    disabled={disabled}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: 1, height: "3rem" }}
                    onClick={() => {
                        textAction("");
                        btnAction();
                    }}
                    disabled={disabled}
                >
                    <SendIcon />
                </Button>
                {recAllowed ? (
                    !recording ? (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ ml: 1, height: "3rem" }}
                            onClick={startSpeechRecognition}
                            disabled={disabled}
                        >
                            <RadioButtonCheckedIcon />
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ ml: 1, height: "3rem" }}
                            onClick={stopSpeechRecognition}
                            disabled={disabled}
                        >
                            <StopIcon />
                        </Button>
                    )
                ) : (
                    <></>
                )}
            </Box>
        </ThemeProvider>
    );
};
