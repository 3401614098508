import React, { useState, useRef } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { ToggleTextButton } from "../chat/toggleButton";

export default function OptionDialog({ action, open, setOpen }) {
    // const [open, setOpen] = useState(state);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [industry, setIndustry] = useState("");
    const industryRefs = useRef({});
    const options = ["finance", "medical"];

    const handleClickIndustry = (newIndustry) => {
        if (industry !== newIndustry && industry !== "") {
            if (industryRefs.current) {
                industryRefs.current[industry].click();
            }
        }

        setIndustry(newIndustry);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSave = () => {
        action(industry);
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                sx={{
                    minHeight: "20rem",
                    minWidth: "20rem",
                }}
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    sx={{
                        paddingRight: "5rem",
                        backgroundColor: "#FAF9F8",
                        fontWeight: "bold",
                        color: "#353935",
                    }}
                >
                    CHOOSE YOUR INDUSTRY
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ backgroundColor: "#FAF9F8" }}>
                    <Grid>
                        {options.map((option) => {
                            return (
                                <ToggleTextButton
                                    ref={(ref) =>
                                        (industryRefs.current[option] = ref)
                                    }
                                    text={option}
                                    originalStyle={{
                                        backgroundColor: "#efefef",
                                        color: "#000000",
                                        borderRadius: 3,
                                        borderColor: "#000000",
                                        border: 1,
                                        "&:hover": {
                                            backgroundColor: "#62d75f",
                                        },
                                        m: "0.5rem",
                                    }}
                                    newStyle={{
                                        backgroundColor: "#62d75f",
                                        color: "#ffffff",
                                        borderRadius: 3,
                                        border: 0,
                                        "&:hover": {
                                            backgroundColor: "#62d75f",
                                        },
                                        m: "0.5rem",
                                    }}
                                    action={() => {
                                        handleClickIndustry(option);
                                    }}
                                />
                            );
                            
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: "#FAF9F8" }}>
                    <Button
                        sx={{
                            color: "#FFFFFF",
                            backgroundColor: "#509458",
                            fontWeight: "bold",
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

// #ECFFDC
