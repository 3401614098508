export const initialState = {
    isAuthorized: false,
    token: "",
    chats: [],
    industry: "",
};
export const AuthSlice = (set) => ({
    isAuthorized: false,
    token: "",
    setIsAuthorized: (val) => set({ isAuthorized: val }),
    setToken: (token) => set({ token: token }),
});
export const ChatSlice = (set) => ({
    chats: new Set(),
    //addChat: (newMessage) => set({ chats: [...get().chats, newMessage] }),
    addChat: (newMessage) =>
        set((state) => ({ chats: [state.chats, newMessage] })),
});
export const UserSlice = (set) => ({
    industry: "",
    setIndustry: (val) => set({ industry: val }),
});
