import React from "react";
import { Typography, CircularProgress } from "@mui/material";

const LoadingOverlay = () => {
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 999,
            }}
        >
            <CircularProgress />
            <Typography>Loading...</Typography>
        </div>
    );
};

export default LoadingOverlay;
