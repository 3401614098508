import axios from "axios";
import { useStore } from "../constants/store/store";
import { getAuth } from "firebase/auth";

const API = axios.create({
    baseURL: "https://gpter-ff5b485e1dad.herokuapp.com/", // REMEMBER to change back to heroku link when deploying
    //baseURL: "http://localhost:5000/",
    headers: {
        Accept: "application/json",
    },
});

// Apply the interceptor to update Authorization header before each request
API.interceptors.request.use((config) => {
    config.headers.Authorization = useStore.getState().token;
    return config;
});

export const APIStoreChat = (chat_history, threadid) => {
    return API.post(`/store_chat/`, {
        chat_history: chat_history,
        threadid: threadid,
    });
};
export const APILoadChat = () => API.get(`/load_chat/`);

export const APIStoreUser = (goals, age, industry, frequency) => {
    return API.post(`/store_user/`, {
        goals: goals,


        age: age,
        industry: industry,
        frequency: frequency,
    });
};
export const APILoadUser = () => API.get(`/load_user/`);

export const APIStoreVocab = (vocab, industryName) => {
    return API.post(`/store_vocab/`, {
        vocab: vocab,
        industryName: industryName,
    });
};
export const APILoadVocab = (industryName) =>
    API.get(`/load_vocab/`, { params: { industryName: industryName } });
