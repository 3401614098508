import React, { useEffect, useState } from "react";
import {
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    updateProfile,
    AuthErrorCodes,
} from "firebase/auth";
import { GoogleLogin } from "./../../constants/GoogleAuth";
import { useStore } from "../../constants/store/store";
import {
    Container,
    Typography,
    TextField,
    Button,
    Grid,
    CssBaseline,
    Link,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
    IconButton,
    Avatar,
} from "@mui/material";
import { styles } from "./styles";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "./../../assets/google-icon.png";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import LoadingOverlay from "../../components/misc/loading";
import { APIStoreUser } from "../../actions/APIstoreChat";

const SignupPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const auth = getAuth();
    const [loading, setLoading] = useState(true);
    const { setIsAuthorized, isAuthorized, setToken } = useStore();

    useEffect(() => {
        auth.onAuthStateChanged(async (user) => {
            // set token
            if (user) {
                const token = await user.getIdToken();
                setToken(token);
                setIsAuthorized(true);
                navigate("/home");
            } else {
                setLoading(false);
                await auth.signOut();
            }
        });
    }, []);
    const useSignUp = async () => {
        if (email === "") {
            setError("Sign up error: email is empty");
        } else if (password === "") {
            setError("Sign up error: password is empty");
        } else if (displayName === "") {
            setError("Sign up error: display name is empty");
        } else if (password.length < 10) {
            setError(
                "Sign up error: password needs to be longer than 10 characters"
            );
        } else if (password !== confirmPassword) {
            const cause = "Passwords do not match";
            setError(cause);
        } else {
            setLoading(true);
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    email,
                    password
                );

                const result = await signInWithEmailAndPassword(
                    auth,
                    email,
                    password
                );
                onAuthStateChanged(auth, async (user) => {
                    if (user) {
                        updateProfile(user, {
                            displayName: displayName,
                        });
                        setIsAuthorized(true);
                        const token = await user.getIdToken();
                        setToken(token);
                        // await APIStoreUser();
                        navigate("/home");
                    }
                });
            } catch (err) {
                setLoading(false);
                if (err.code === AuthErrorCodes.EMAIL_EXISTS) {
                    setError("An account with this email already exists");
                } else {
                    setError("Signing up error: Failed to create account");
                }
            }
        }
    };
    const useGoogleLogin = async () => {
        setLoading(true);
        await GoogleLogin();
    };

    return (
        <ThemeProvider theme={styles}>
            {loading ? <LoadingOverlay /> : <></>}
            <Container
                component="main"
                style={{
                    padding: 0,
                    width: "100%",
                    height: "100%",
                    maxWidth: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <AppBar
                    position="relative"
                    style={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "green",
                    }}
                >
                    <Toolbar>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "-5px",
                                padding: "0",
                                color: "green",
                                marginTop: "-10px",
                            }}
                        >
                            <img
                                onClick={() => {
                                    navigate("/");
                                }}
                                src={BizLangIcon}
                                height={75}
                                width={100}
                            />
                        </Typography>
                        <Typography
                            variant="h6"
                            color="inherit"
                            noWrap
                            onClick={() => {
                                navigate("/");
                            }}
                            sx={{
                                margin: "0",
                                padding: "0",
                                color: "white",
                                fontWeight: "bold",
                                fontSize: "1.6rem",
                                fontFamily: "monospace",
                                paddingTop: "15px",
                            }}
                        >
                            BIZLANG
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container
                    component="container"
                    maxWidth="xs"
                    style={{ flexGrow: 1 }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h1" variant="h4">
                            Welcome!
                        </Typography>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="displayName"
                                label="Display Name"
                                name="displayName"
                                autoFocus
                                onChange={(e) => {
                                    setDisplayName(e.target.value);
                                    setError("");
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setError("");
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setError("");
                                }}
                            />
                            {!(error === "") ? (
                                <Typography
                                    style={{
                                        color: "red",
                                        fontWeight: "bold",
                                        margin: 0,
                                    }}
                                >
                                    {error}
                                </Typography>
                            ) : (
                                <></>
                            )}
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={useSignUp}
                            >
                                Sign Up
                            </Button>
                            <Typography
                                style={{
                                    color: "black",
                                    fontWeight: "normal",
                                    margin: 0,
                                }}
                            >
                                or
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, padding: 0 }}
                                style={{
                                    backgroundColor: "#649eff",
                                    justifyContent: "center",
                                }}
                                onClick={useGoogleLogin}
                            >
                                <IconButton
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                    }}
                                >
                                    <Avatar
                                        src={GoogleIcon}
                                        alt="Custom Icon"
                                        style={{
                                            height: "2.5rem",
                                            width: "2.5rem",
                                            margin: 0,
                                            marginRight: 10,
                                            backgroundColor: "white",
                                            padding: 8,
                                        }}
                                    />
                                </IconButton>
                                Sign in with Google
                            </Button>

                            <Grid item>
                                <Link
                                    variant="body1"
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    {"Already have an account? Sign In!"}
                                </Link>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
                <Box
                    sx={{
                        flexGrow: 0,
                        justifyContent: "center",
                        display: "flex",
                        alignContent: "center",
                    }}
                    component="footer"
                >
                    <Toolbar
                        style={{
                            backgroundColor: "green",
                            width: "100%",
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="caption" color="white">
                            Copyright ©2024 BIZLANG. All Rights Reserved
                        </Typography>
                    </Toolbar>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SignupPage;
