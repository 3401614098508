import { createTheme } from "@mui/material/styles";

export const styles = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontFamily: "Open Sans, sans-serif",
                    backgroundColor: "#62d75f",
                    color: "rgb(255, 255, 255)",
                    borderRadius: 20,
                    "&:hover": {
                        backgroundColor: "#11823b",
                    },
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderColor: "#62d75f",
                    borderRadius: 25,
                    borderWidth: 2,
                    width: "100%",
                    "&:hover": {
                        borderColor: "#11823b",
                    },
                    "&:isActive": {
                        borderColor: "#11823b",
                    },
                },
            },
        },
    },
});
