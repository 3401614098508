import React, { useState, useEffect, useRef } from 'react';
import vocabData from '../../assets/vocabs/wordle.json';
import { useStore } from "../../constants/store/store";
import { Container, CssBaseline, Box, Grid, Button } from "@mui/material";
import OptionDialog from "../../components/SetOptionsUI/DifficultyDialog.js";


const Wordle = () => {
  const [word, setWord] = useState('');
  const [guesses, setGuesses] = useState([]);
  const [currentGuess, setCurrentGuess] = useState('');
  const [definition, setDefinition] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [gridSize, setGridSize] = useState(6);
  const [difficulty, setDifficulty] = useState('easy');
  const [difficultyChanged, setDifficultyChanged] = useState(false);
  const [showDifficultyModal, setShowDifficultyModal] = useState(false);
  const buttonRef = useRef(null);

  const [chosenIndustry, setChosenIndustry] = useState(
    useStore((state) => state.industry)
);


  const handleDifficultyChange = (newDifficulty) => {
    setDifficulty(newDifficulty);
    setDifficultyChanged(true);
    setShowDifficultyModal(false);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    window.blur();
  };

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
    window.blur();
  }, []);

useEffect(() => {
  const selectedWord = getRandomWord(chosenIndustry, difficulty);
  if (selectedWord) {
    const { word, definition: def } = selectedWord;
    setWord(word);
    setDefinition(def);
    setGridSize(Math.max(6, word.length));
    setDifficultyChanged(false);
  } else {
    alert('No words found for the selected difficulty level.');
  }
}, [difficulty, chosenIndustry, difficultyChanged]);

const handleKeyDown = async (event) => {
    if (event.key === 'Enter' && currentGuess.length === word.length) {
      buttonRef.current.blur();
      const isValidWord = await checkWordValidity(currentGuess);
      if (!isValidWord) {
        alert('Not a valid word!');
        return;
    }

    setGuesses([...guesses, currentGuess]);
    setCurrentGuess('');
    setCurrentRow(currentRow + 1);

    if (currentGuess === word) {
      setGameOver(true);
    } else if (currentRow === gridSize - 1) {
      setGameOver(true);
    }
  } else if (event.key === 'Backspace') {
    setCurrentGuess(currentGuess.slice(0, -1));
  } else if (event.key.match(/^[a-zA-Z]$/)) {
    if (currentGuess.length < word.length) {
      setCurrentGuess(currentGuess + event.key.toUpperCase());
    }
  }
};

useEffect(() => {
  window.addEventListener('keydown', handleKeyDown);
  return () => {
    window.removeEventListener('keydown', handleKeyDown);
  };
}, [handleKeyDown, currentGuess, word]);

const checkWordValidity = async (guess) => {
  const response = await fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${guess.toLowerCase()}`);
  const data = await response.json();
  return data.title !== 'No Definitions Found';
};

//checks if user selects different difficulty
useEffect(() => {
  if (difficultyChanged) {
    setGuesses([]);
    setCurrentGuess('');
    setCurrentRow(0);
    setGameOver(false);
  }
}, [difficultyChanged]);



const getGridSize = () => {
  return {
    gridTemplateColumns: `repeat(${word.length}, 1fr)`,
  };
};

const getLetterStatus = (letter, index, rowIndex) => {
  if (rowIndex >= guesses.length) return '';

  const guess = guesses[rowIndex];
  const wordArray = word.split('');
  const guessedLetters = guess.split('');

  if (letter === wordArray[index]) {
    return styles.correct;
  }

    const letterCountInWord = wordArray.filter(l => l === letter).length;
    const letterCountInGuess = guessedLetters.filter(l => l === letter).length;

    if (wordArray.includes(letter) && letterCountInGuess <= letterCountInWord) {
      const correctCountInGuess = guessedLetters.reduce(
        (acc, curr, i) => curr === letter && wordArray[i] === letter ? acc + 1 : acc,
        0
      );
      const incorrectCountInGuess = guessedLetters.reduce(
        (acc, curr, i) => curr === letter && wordArray[i] !== letter ? acc + 1 : acc,
        0
      );

      if (incorrectCountInGuess > 0 && incorrectCountInGuess + correctCountInGuess <= letterCountInWord) {
        return styles.present;
      }
    }

    return styles.absent;
};

const getRandomWord = (category, difficulty) => {
  const categoryData = vocabData[category];
  if (!categoryData) {
    return null;
  }

  const difficultyData = categoryData.find(item => item[difficulty]);
  if (!difficultyData) {
    return null;
  }

  const wordObjects = difficultyData[difficulty];
  if (wordObjects.length === 0) {
    return null;
  }

  const randomIndex = Math.floor(Math.random() * wordObjects.length);
  const randomWordObject = wordObjects[randomIndex];
  const word = Object.keys(randomWordObject)[0];
  const definition = Object.values(randomWordObject)[0];
  return { word: word.toUpperCase(), definition };
};

const styles = {
  wordle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wordleGrid: {
    display: 'grid',
    gridGap: '5px',
    gridTemplateRows: `repeat(${gridSize}, 1fr)`,
  },
  wordleCell: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    border: '2px solid #ccc',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  correct: {
    backgroundColor: 'green',
    color: '#fff',
  },
  present: {
    backgroundColor: 'yellow',
  },
  absent: {
    backgroundColor: '#ccc',
  },
};

  return (
    
    <div style={styles.wordle}>
       <Button
       ref = {buttonRef}
       tabIndex={-1} // Add this line
        sx={{
          margin: "20px",
          backgroundColor: "#509458",
          color: "#FFFFFF",
          padding: "0.75rem",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#EFFFEF",
            color: "#509458",
          },
          minWidth: "7rem",
          borderRadius: "20px",
        }}
        onClick={() => setShowDifficultyModal(true)}
      >
        {difficulty.toUpperCase()}
      </Button>
      {showDifficultyModal && (
        <OptionDialog
          open={showDifficultyModal}
          setOpen={setShowDifficultyModal}
          action={handleDifficultyChange}
          options={["Easy", "Medium", "Hard"]}
          buttonRef={buttonRef}
        />
      )}
      <div style={{ ...styles.wordleGrid, ...getGridSize() }}>
        {Array(gridSize)
          .fill()
          .map((_, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {Array(word.length)
                .fill()
                .map((_, cellIndex) => {
                  const letter =
                    rowIndex < guesses.length
                      ? guesses[rowIndex][cellIndex] || ''
                      : rowIndex === currentRow
                      ? currentGuess[cellIndex] || ''
                      : '';

                  return (
                    <div
                      key={cellIndex}
                      style={{
                        ...styles.wordleCell,
                        ...getLetterStatus(letter, cellIndex, rowIndex),
                      }}
                    >
                      {letter}
                    </div>
                  );
                })}
            </React.Fragment>
          ))}
      </div>
      {gameOver && (
  <div>
    {currentRow === gridSize ? (
      <>
        <p>The word was: {word}</p>
        <p>Definition: {definition}</p>
      </>
    ) : (
      <>
      You got the word!
      <p>Definition: {definition}</p>
    </>
    )}
  </div>
)}
    </div>
  );
};



export default Wordle;


