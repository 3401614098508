import { createTheme } from "@mui/material/styles";

export const styles = createTheme({
    // palette: {
    //     primary: {
    //         main: "#b71c1c",
    //         contrastText: "white",
    //     },
    // },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1rem",
                    fontFamily: "Open Sans, sans-serif",
                    backgroundColor: "#62d75f",
                    color: "rgb(255, 255, 255)",
                    borderRadius: 25,
                    "&:hover": {
                        backgroundColor: "#11823b",
                    },
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    backgroundColor: "black",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#11823b",
                    fontFamily: "sans-serif",
                    fontWeight: "700",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderColor: "#62d75f",
                    borderRadius: 25,
                    borderWidth: 2,
                    "&:hover": {
                        borderColor: "#11823b",
                    },
                    "&:isActive": {
                        borderColor: "#11823b",
                    },
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "sans-serif",
                    color: "#000000",
                    textDecorationColor: "#000000",
                    fontWeight: "normal",
                    "&:hover": {
                        color: "#000000",
                    },
                },
            },
        },
    },
});
