import React, { useState, useRef } from "react";
import {
    Container,
    Typography,
    TextField,
    Button,
    CssBaseline,
    Box,
    AppBar,
    Toolbar,
    Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BizLangIcon from "../../assets/bizlang_logo.svg";
import LoadingOverlay from "../../components/misc/loading";
import { APIStoreUser } from "../../actions/APIstoreChat";
import { ToggleTextButton } from "../../components/chat/toggleButton";

const PersonalizePage = () => {
    const [industry, setIndustry] = useState("");
    const [goals, setGoals] = useState("");
    const [age, setAge] = useState("");
    const [frequency, setFrequency] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const ageRefs = useRef({});
    const options = ["16-18", "19-21", "22-25", "26-30", "30+"];

    const handleClickAge = (newAge) => {
        if (age !== newAge && age !== "") {
            if (ageRefs.current) {
                ageRefs.current[age].click();
            }
        }

        setAge(newAge);
        setError("");
    };

    const useSubmit = async () => {
        if (goals === "" || age === "" || industry === "" || frequency === "") {
            setError(
                "Please fill out all the information to help us make a more personalized experience for you!"
            );
            return;
        }
        setLoading(true);
        try {
            await APIStoreUser(goals, age, industry, frequency);
            navigate("/home");
        } catch (e) {
            setLoading(false);
        }
    };
    return (
        <Container component="main" maxWidth="xl" style={{ padding: 0 }}>
            {loading ? <LoadingOverlay /> : <></>}
            <AppBar
                position="relative"
                style={{
                    width: "100%",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "green",
                }}
            >
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                            margin: "-5px",
                            padding: "0",
                            color: "green",
                            marginTop: "-10px",
                        }}
                    >
                        <img
                            src={BizLangIcon}
                            height={75}
                            width={100}
                            alt="icon"
                        />
                    </Typography>
                    <Typography
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{
                            margin: "0",
                            padding: "0",
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "1.6rem",
                            fontFamily: "monospace",
                            paddingTop: "15px",
                        }}
                    >
                        BIZLANG
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="container" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{ fontWeight: "bold" }}
                    >
                        Let's get to know you!
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            fullWidth
                            id="industry"
                            label="What is your main/interested industry?"
                            name="industry"
                            onChange={(e) => {
                                setIndustry(e.target.value);
                                setError("");
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            id="goals"
                            label="What are your goals for joining this app?"
                            name="goals"
                            onChange={(e) => {
                                setGoals(e.target.value);
                                setError("");
                            }}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="frequency"
                            label="How often do you plan on using this app"
                            id="frequency"
                            onChange={(e) => {
                                setFrequency(e.target.value);
                                setError("");
                            }}
                        />
                        {/* <TextField
                            margin="normal"
                            fullWidth
                            name="age"
                            label="Age"
                            id="age"
                            onChange={(e) => {
                                setAge(e.target.value);
                                setError("");
                            }}
                        /> */}

                        <Grid sx={{ display: "flex", flexDirection: "row" }}>
                            <Typography
                                sx={{
                                    fontSize: "16px",
                                    marginTop: "15px",
                                    fontWeight: "70%",
                                }}
                            >
                                Age:
                            </Typography>
                            {options.map((option) => {
                                return (
                                    <ToggleTextButton
                                        ref={(ref) =>
                                            (ageRefs.current[option] = ref)
                                        }
                                        text={option}
                                        originalStyle={{
                                            backgroundColor: "#efefef",
                                            color: "#000000",
                                            borderRadius: 3,
                                            borderColor: "#000000",
                                            border: 1,
                                            "&:hover": {
                                                backgroundColor: "#62d75f",
                                            },
                                            m: "0.5rem",
                                        }}
                                        newStyle={{
                                            backgroundColor: "#62d75f",
                                            color: "#ffffff",
                                            borderRadius: 3,
                                            border: 0,
                                            "&:hover": {
                                                backgroundColor: "#62d75f",
                                            },
                                            m: "0.5rem",
                                        }}
                                        action={() => {
                                            handleClickAge(option);
                                        }}
                                    />
                                );
                            })}
                        </Grid>

                        {!(error === "") ? (
                            <Typography
                                style={{
                                    color: "red",
                                    fontWeight: "bold",
                                    margin: 0,
                                }}
                            >
                                {error}
                            </Typography>
                        ) : (
                            <></>
                        )}
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: "green",
                                fontWeidhgt: "bold",
                                fontSize: "16",
                            }}
                            onClick={useSubmit}
                        >
                            Start!
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Container>
    );
};

export default PersonalizePage;
