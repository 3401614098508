import React, { useState, useEffect } from "react";
import { Button, ThemeProvider, Grid, Container, Box } from "@mui/material";
import { styles } from "./styles";
import { ToggleTextButton } from "./toggleButton";import { APIStoreVocab, APIStoreChat, APILoadVocab } from "../../actions/APIstoreChat";


export const TextOptions = ({ vocabs = [], action, key,chosenIndustry }) => {
    const [chosen, setChosen] = useState([]);
    const [existingVocabs, setExistingVocabs] = useState(new Map());
    const [displayVocab, setDisplayVocab] = useState([]);

    useEffect(() => {
        // Async IIFE
        (async () => {
            try {
                const res = await APILoadVocab(chosenIndustry);
                if (res && Array.isArray(res.data.vocabs)) {
                    const vocabTerms = new Set(res.data.vocabs.map(vocab => Object.keys(vocab)[0]));
                    setExistingVocabs(vocabTerms);
                    setDisplayVocab(vocabs.map((vocab)=>{
                        if(!vocabTerms.has(vocab)){
                            return vocab;
                        }

                    }))

                } else {
                    console.log("Vocab data is not in the expected format:", res);
                }
            } catch (error) {
                console.error("Error loading vocab:", error);
            }
        })();
    }, [chosenIndustry]);
    
    
    

    const btnAction = (word) => {
        if (chosen.includes(word)) {
            // If the word is already selected, remove it from the chosen list
            setChosen(chosen.filter(c => c !== word));
        } else {
            // Add the word to the chosen list
            setChosen([...chosen, word]);
    
            // Check if the word is in existingVocabs.
            if (existingVocabs.has(word)) {
                // If the word is in existingVocabs, log a message or handle accordingly.
                console.log(`${word} is in the list of loaded vocabularies. It will be added but might need review.`);
                // Here, you could also trigger some UI feedback or a confirmation dialog.
            }
        }
    };
    

    const importVocab = () => {
        // Simplify checking for existing terms using the Map
        const alreadyExists = chosen.some(term => existingVocabs.has(term));
        if (alreadyExists) {
            console.log("Some terms already exist. Do you want to continue?");
            // Implement a confirmation dialog or UI feedback
        } else {
            APIStoreVocab(chosen, chosenIndustry); 
            action(chosen);
        }
    };

    return (
        <ThemeProvider theme={styles}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 4,
                    ml: 0,
                    mb: 2,
                    mr: 5,
                    p: 0,
                    maxWidth: "90%",
                    borderRadius: "15px",
                    border: 2,
                    borderColor: "#62d75f",
                    MuiCssBaseline: {
                        "@global": {
                            "*::-webkit-scrollbar": {
                                width: "3px",
                            },
                            "*::-webkit-scrollbar-track": {
                                background: "#E4EFEF",
                            },
                            "*::-webkit-scrollbar-thumb": {
                                background: "#1D388F61",
                                borderRadius: "2px",
                            },
                        },
                    },
                }}
            >
                <Grid>
                    {displayVocab.length > 0?displayVocab.map((vocab) => {
                        if(!existingVocabs.has(vocab.split(":")[0].trim())){
                            console.log(vocab.split(":")[0].trim());

                        return (
                            <ToggleTextButton
                                text={vocab}
                                originalStyle={{
                                    backgroundColor: "#efefef",
                                    color: "#000000",
                                    borderRadius: 3,
                                    borderColor: "#000000",
                                    border: 1,
                                    "&:hover": {
                                        backgroundColor: "#62d75f",
                                    },
                                    m: "0.5rem",
                                }}
                                newStyle={{
                                    backgroundColor: "#62d75f",
                                    color: "#ffffff",
                                    borderRadius: 3,
                                    border: 0,
                                    "&:hover": {
                                        backgroundColor: "#62d75f",
                                    },
                                    m: "0.5rem",
                                }}
                                action={() => {
                                    btnAction(vocab);
                                }}
                            />
                        );
                            }
                            else{
                                console.log(vocab,"found");
                            }
                    }):(
                        <Button
                                text={"no new vocabulary at this time"}
                                originalStyle={{
                                    backgroundColor: "#efefef",
                                    color: "#000000",
                                    borderRadius: 3,
                                    borderColor: "#000000",
                                    border: 1,
                                    "&:hover": {
                                        backgroundColor: "#62d75f",
                                    },
                                    m: "0.5rem",
                                }}
                                newStyle={{
                                    backgroundColor: "#62d75f",
                                    color: "#ffffff",
                                    borderRadius: 3,
                                    border: 0,
                                    "&:hover": {
                                        backgroundColor: "#62d75f",
                                    },
                                    m: "0.5rem",
                                }}
                            />)
                    }
                </Grid>
                {chosen.length > 0 ? (
                    <Container
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}
                        disableGutters
                    >
                        <Button
                            sx={{ mb: "2px", mr: "2px", borderRadius: "15px" }}
                            onClick={importVocab}
                        >
                            Import
                        </Button>
                    </Container>
                ) : (
                    <></>
                )}
            </Box>
        </ThemeProvider>
    );
};
