import React, { useState } from "react";
import {
    Typography,
    IconButton,
    Card,
    CardActionArea,
    CardContent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

export default function FlashCard({
    word,
    definition,
    nextAction = () => {},
    prevAction = () => {},
}) {
    const [showDef, setShowDef] = useState(false);

    return (
        <React.Fragment>
            <Card
                elevation={10}
                sx={{
                    display: "flex",
                    "@media (min-width: 1200px)": {
                        minWidth: "35rem",
                        height: "20rem",
                    },
                    maxWidth: "100%",
                    minWidth: "100%",
                    marginBottom: "2rem",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <CardActionArea
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "10%",
                    }}
                    onClick={() => {
                        prevAction();
                        setShowDef(false);
                    }}
                >
                    <IconButton
                        aria-label="back"
                        sx={{}}
                        onClick={() => {
                            prevAction();
                            setShowDef(false);
                        }}
                    >
                        <NavigateBeforeIcon />
                    </IconButton>
                </CardActionArea>
                <CardActionArea
                    sx={{ width: "80%", height: "100%" }}
                    onClick={() => {
                        setShowDef(!showDef);
                    }}
                >
                    <CardContent>
                        {!showDef ? (
                            <Typography
                                sx={{
                                    fontSize: "28px",
                                }}
                            >
                                {word}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontSize: "28px",
                                }}
                            >
                                {definition}
                            </Typography>
                        )}
                    </CardContent>
                </CardActionArea>

                <CardActionArea
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "10%",
                    }}
                    onClick={() => {
                        nextAction();
                        setShowDef(false);
                    }}
                >
                    <IconButton
                        aria-label="back"
                        sx={{}}
                        onClick={() => {
                            nextAction();
                            setShowDef(false);
                        }}
                    >
                        <NavigateNextIcon />
                    </IconButton>
                </CardActionArea>
            </Card>
        </React.Fragment>
    );
}
